<template>
    <div id='container'>
     <div class="header">
        <van-nav-bar
        title="推广赚钱"
        left-text="返回"
        right-text=""
        left-arrow
        @click-left="onClickLeft"
        />
    </div>
    <div class="content">
        <img src="../../assets/images/code.jpg" alt="">
        <div v-if="success" class="pic" ref="qrCode">

        </div>
        <div class="code">
            {{ewm?ewm:'生成中,返回查看'}}
            <!-- 已更新 -->
        </div>
        <div class="copy" @click="copy">
            <!-- 截图/复制分享 -->
            复制推广链接
        </div>
         <div class="copy_">
            <!-- 截图/复制分享 -->
            注：推荐使用微信扫码识别
        </div>
    </div>
   </div>
</template>

<script>
import { mapState } from "vuex"
import { insertCode,getCode,getAllCode } from "../../model/user"
import QRCode from "qrcodejs2"
import { Toast } from 'vant';


export default {
    data() {
            return {
                code:"",
                ewm:"",
                qrCodeUrl:null,
                ermsg:"",
                success:false,
                str :`<p style='font-size:14px'>生成中,返回查看</p>`
            }
        },
     computed:{
        // ...mapMutations(["changeFlag","changeFlag1"]),
           ...mapState({
            loginStatus : state=>state.user.loginStatus,
            userInfo : state=>state.user.userInfo
        }),

    },
    created() {
        // this.$store.state.user.code
        // alert()
        // alert(this.$store.state.user.code);
        // alert(localStorage.getItem('code'));

        let first = localStorage.getItem('code')
        // alert(first)
        if(first!=null){
            this.success = true
            this.ermsg = `51风流-楼凤小姐兼职包养信息平台http://dk51.xyz?/code=${first},更多信息尽在51tea.xyz!`
        }
        this.ewm = first
        this.code = this.filterStr()
        this.getAllCode()
        this.getCode()
        this.$nextTick(()=>{
            if(this.ewm!=''){
                this.creatQrCode()
            }
         })

    },

    methods: {
        getAllCode(){
            getAllCode().then((res)=>{
                if(res.status == 200){
                    console.log(res.data.data,777);
                    res.data.data.forEach((item)=>{
                        if(item.code == this.code){
                            this.filterStr()
                        }else{
                            console.log(1);
                        }
                    })
                }
            })
        },
        onClickLeft(){
                this.$router.go(-1)
            },
         //随机四位验证码
         filterStr(){
        //数字+字母
            var str = 'QWERTYUIOPASDFGHJKLZXCVBNMabcdefghjklmnpqrstuvwxyz';
            var res_ = '';
            for (let i = 0; i < 4; i++) {
                //随机产生字符串的下标
                var n = parseInt(Math.random() * str.length)
                res_ += str[n]
            }
            // getAllCode().then((res)=>{
            //     if(res.status == 200){
            //         console.log(res.data.data,777);
            //         res.data.data.forEach((item)=>{
            //             if(item.code == res_){
            //                this.filterStr()
            //             }else{
            //             return res_

            //             }
            //         })
            //     }
            // })
                return res_
            },
        // 获取code
        getCode(){
              let data = {
                username:this.userInfo.username,
            }
            getCode(data).then((res)=>{
                if(res.status == 200){
                    console.log(res);
                if(res.data.data[0].code !=''){
                        console.log(1);
                    }else{
                let data_ = {
                    username:this.userInfo.username,
                    code:this.code
                }
                // 新增
                this.$store.commit('updataCode',this.code)
                insertCode(data_).then((res)=>{
                    console.log(res);
                })
         }
                
                }
            })
        },
        //新增code
        // insertCode(){
        //     let data = {
        //         username:this.userInfo.username,
        //         code:this.code
        //     }
        //     insertCode(data).then((res)=>{
        //         console.log(res,666);
        //     })
        // } 
    creatQrCode() {
      if(this.qrCodeUrl){
      new QRCode(this.$refs.qrCode, {
        text: this.ermsg, //可以是链接，也可以是文本
        width: 200, //二维码的宽度
        height: 200, //二维码的高度
        colorLight: "#ffffff", //二维码背景色
        colorDark: "#000000", //二维码前景色，以实现红码为例
        correctLevel: QRCode.CorrectLevel.H, //纠错等级
      });
      }else{
        this.$refs.qrCode.innerHTML = ""//当从新调用时把之前生成的二维码清空
         new QRCode(this.$refs.qrCode, {
        text: this.ermsg, //可以是链接，也可以是文本
        width: 200, //二维码的宽度
        height: 200, //二维码的高度
        colorLight: "#ffffff", //二维码背景色
        colorDark: "#000000", //二维码前景色，
        correctLevel: QRCode.CorrectLevel.H, //纠错等级
        });
      }
    },
    copy(){
        if(this.ewm!="" && this.ewm!=null){
            this.copyText()
            Toast.success('复制成功');

        }
    },
    copyText() {
    var input = document.createElement("input");// 创建input
    input.value = this.ermsg; // 赋值
    document.body.appendChild(input); // 添加dom
    input.select(); // 选中input
    document.execCommand("Copy"); // 复制到剪贴板
    document.body.removeChild(input); // 清除input
    }
},





  async mounted() {
    // this.ces = true
      // this.ces = true

      // this.changeFlag();
      this.$store.commit('changeFlag')
  },
  destroyed() {
      // this.changeFlag1();
      this.$store.commit('changeFlag1')
  },

}
</script>

<style scoped>
*{
    margin: 0;
    padding: 0;
}
li {
  list-style: none;
}
#container{
    width: 100%;
    height: 100%;
    background-color: #f5f5f5;

}
#container .header{
    width: 100%;
    height: 60px;
    /* color: #ec4899; */
}
#container .logo{
    position: absolute;
    top: 10px;
    left: 34%;
    z-index: 99;
}
#container .logo img{
    height: 45px;
}
#container .content{
    width: 100%;
    height: calc(100% - 60px);
    overflow-y: scroll;
    padding: 0;
    box-sizing: border-box;
    position: relative;
}
#container .content img{
    width: 100%;
}

 /deep/.van-nav-bar__text{
   
    color: #ec4899 !important;
}
 /deep/.van-nav-bar__title{
    color: #ec4899 !important;
    font-size: 18px;
}
/deep/.van-nav-bar .van-icon{
    color: #ec4899 !important;
}
/deep/.van-nav-bar__content{
    height: 60px !important;
}
#container .content .code{
    position: absolute;
    top: 9.4rem;
    right: .8rem;
    width: 3.6rem;

    height: 35px;
    background-color: #044148;
    /* background-color: #fff; */
    color: #f5f5f5;
    text-align: left;
    line-height: 20px;
    font-size: 18px;
}
#container .content ::v-deep .pic{
    position: absolute;
    top: 4.25rem;
    right: 2.8rem;
    width: 4.4rem;
    height: 4.4rem;

    background-color: #044148;
}
#container .content .pic ::v-deep  img{
    width: 100%;
    /* height: 100%; */
}
#container .content .copy{
    position: absolute;
    top: 10.95rem;
    right: 3.7rem;
    width: 2.5rem;

    height: 23px;
    /* background-color: #fff; */
    /* background-color: #fff; */
    color: #044148;
    text-align: center;
    line-height: 35px;
    font-size: 14px;
}

#container .content .copy_{
    position: absolute;
    top: 11.95rem;
    right: 3.1rem;
    width: 3.9rem;

    height: 23px;
    /* background-color: #fff; */
    /* background-color: #fff; */
    color: #fff;
    text-align: center;
    line-height: 35px;
    font-size: 12px;
}
/* #044148 */
</style>